import React, {useState} from 'react';

import {Flex} from 'rebass';

import './index.css';

function FaqItem({faq}) {
  const [open, setOpen] = useState(faq.open);

  return (
    <div className={'faq ' + (open ? 'open' : '')}>
      <Flex
        style={{gap: 10}}
        alignItems="baseline"
        onClick={() => setOpen(!open)}>
        <div className="arrow"></div>
        <div className="faq-question">{faq.question}</div>
      </Flex>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
}
export default FaqItem;
